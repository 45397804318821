<template>
  <div id="subscription-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>Welcome to Phase 1 - {{ PageTitle }}</h2>

        <v-spacer />
        <v-btn
          v-if="TableOptions1.CreateFlag"
          medium
          rounded
          color="orange"
          class="font-size-h6 mr-3 my-3 white--text"
          @click.prevent="addRecordPrompt = true"
        >
          &nbsp; Add member &nbsp;
        </v-btn>
        <v-btn
          medium
          @click.prevent="refreshPageData"
          color="#a4c639"
          elevation="30"
          rounded
          class="font-size-h6 mr-3 my-3 white--text"
          >&nbsp; Refresh &nbsp;
        </v-btn>
        <router-link to="/mission-one-lakh/dashboard">
          <v-btn
            medium
            color="#90be6d"
            elevation="30"
            rounded
            class="font-size-h6 mr-3 my-3 white--text"
            >&nbsp; Back &nbsp;
          </v-btn>
        </router-link>
      </v-card-title>

      <v-card-text>
        <h4 class="text-danger">
          Deadline Date - {{ MolSettingValues.DeadLineDateTxt }}
        </h4>
        <br />
        <h4>
          Here let us know the members whom you have assigned for the Mission
          One Lakh committee.
        </h4>
        <h4>
          Minimum {{ MolSettingValues.MinimumCount }} members are required to
          form a committee.
        </h4>
        <h4>
          Maximum {{ MolSettingValues.MaximumCount }} members in a committee.
        </h4>
        <h4>Start by clicking the "Add member" button</h4>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
          <v-overlay
            v-model="OverlayLoadingFlag"
            contained
            class="align-center justify-center"
          ></v-overlay>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="SearchFlag && !StartupLoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container
          class="py-0"
          v-if="!SearchFlag && !StartupLoadingFlag && rows.length == 0"
        >
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3 class="text-primary">{{ rows.length }} members found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-data-iterator
                :items="rows"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:header> </template>

                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.MemberId"
                      cols="4"
                    >
                      <v-card raised hover elevation="25">
                        <v-avatar size="200">
                          <img :src="item.ProfilePic" :alt="item.MemberName" />
                        </v-avatar>
                        <v-card-text>
                          <h3>{{ item.MemberNameTxt }}</h3>
                          <h3 class="text-primary">
                            {{ item.CommitteeMemberDesignation }}
                          </h3>
                          <h5 style="color: chocolate">
                            <v-icon style="color: chocolate"
                              >mdi-phone-classic</v-icon
                            >{{ item.MobileNumber }}
                          </h5>
                          <h5 style="color: mediumseagreen">
                            <v-icon style="color: mediumseagreen"
                              >mdi-email</v-icon
                            >
                            {{ item.Email }}
                          </h5>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            v-if="item.DeleteFlag"
                            elevation="30"
                            shaped
                            tile
                            small
                            color="#ff0000"
                            class="font-size-h6 white--text"
                            @click="deleteData(item, e)"
                          >
                            <v-icon>mdi-delete</v-icon> Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent max-width="70%">
      <committee-member-create
        :addRecordPrompt="addRecordPrompt"
        @hideAddRecordPrompt="hideAddRecordPrompt"
        :LomCode="LomCode"
        v-if="addRecordPrompt"
      ></committee-member-create>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import CommitteeMemberCreate from "@/view/pages/erp/lom/mission-onelakh/CommitteeMemberCreate.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    CommitteeMemberCreate,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,
      CreateFlag: false,

      OverlayLoadingFlag: false,
      rows: [],
      TableOptions1: {
        CreateFlag: false,
      },
      TableColumns1: [],
      selected: [],
      search: "",
      LomCode: "",
      addRecordPrompt: false,
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      MolSettingValues: [],
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log("ZoneCode=" + ZoneCode);
        this.ZoneCode = ZoneCode;

        this.pageData();
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "mol_committee",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);      
    },
    pageData() {
      console.log("pageData called");
      this.StartupLoadingFlag = true;
      this.getMolSetting();
      this.getTableRecords();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      if (flag) {
        this.getTableRecords();
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var YearCode = this.JciYearCode;
      console.log("YearCode=" + YearCode);

      this.rows = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/mission-onelakh/members-list";
      var upload = {
        UserInterface: 1,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.rows = [];
      this.SearchFlag = true;
      this.LoadingFlag = true;
      this.OverlayLoadingFlag = true;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
          thisIns.StartupLoadingFlag = false;
          thisIns.OverlayLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          records = response.data.records;
          console.log(
            "output=" +
              output +
              ", flag=" +
              flag +
              ", records=" +
              JSON.stringify(records)
          );

          var options = records.TableOptions;
          thisIns.TableOptions1 = options;

          if (flag == 1) {
            thisIns.TableColumns1 = records.TableHeader;
            thisIns.rows = records.TableData;

            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.toast("error", error);
          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
        });
    },
    deleteData(tr) {
      console.log("deleteData called");
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });
      if (n1 > 0) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to delete this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteRecord(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
        // this.deleteRecord(tr);
      } else {
        var message = "Kindly select one member to delete";
        this.toast("error", message);
      }
    },
    deleteRecord(tr) {
      // add todo in store and clear all fields in dialog
      console.log("deleteRecord called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });

      if (n1 > 0) {
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/mission-onelakh/member-delete";
        var upload = {
          UserInterface: 1,
          Committee: tr.CommitteeId,
          Lom: tr.MolId,
          CommitteeMember: tr.CommitteeMemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log(
              "output=" + output + ", flag=" + flag + ", records=" + records
            );

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.getTableRecords();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindly select one member to delete";
        this.sweetAlert("error", message, false);
      }
    },
    getMolSetting() {
      console.log("getMolSetting called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/mission-onelakh/mol-setting-show";
      var upload = {
        UserInterface: 1,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.SearchFlag = true;
      this.LoadingFlag = true;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          records = response.data.records;
          console.log(
            "output=" + output + ", flag=" + flag + ", records=" + records
          );

          if (flag == 1) {
            thisIns.MolSettingValues = records;
            // thisIns.toast("success", output);
          } else {
            // thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.toast("error", error);
          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
        });
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#subscription-list {
  .v-btn--floating {
    position: relative;
  }

  .v-speed-dial--bottom {
    bottom: 12%;
  }

  .v-speed-dial--right {
    right: 5%;
  }

  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
}
</style>